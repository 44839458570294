import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout-wiki/index.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Instruments`}</h1>
    <h2>{`Container`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "chain"
        }}>{`Chain`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "drum-machine"
        }}>{`Drum Machine`}</a></li>
    </ul>
    <h2>{`Drum`}</h2>
    <h2>{`Hadware`}</h2>
    <h2>{`Keyboard`}</h2>
    <h2>{`Synth`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "fm-4"
        }}>{`FM-4`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "phase-4"
        }}>{`Phase-4`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "polysynth"
        }}>{`Polysynth`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "sampler"
        }}>{`Sampler`}</a></li>
    </ul>
    <h2>{`The Grid`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      